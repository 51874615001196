.prayer-flags-container {
    position: absolute;
    bottom: -95px;
    width: 100%;
    overflow: hidden;
    height: 60px;
  }
  
  .prayer-flags-strip-wrapper {
    display: flex;
    width: fit-content;
    /* animation: scroll-flags 60s linear infinite; */
  }
  
  .prayer-flags-strip {
    display: flex;
  }
  
  .flag {
    height: 60px;
    width: auto;
    margin-right: 5px;
    object-fit: contain;
    animation: flag-wave 2s ease-in-out infinite;
    animation-delay: calc(var(--i) * 0.1s);
  }
  
  /* Animation continue du ruban complet */
  @keyframes scroll-flags {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Effet de flottement */
  @keyframes flag-wave {
    0%, 100% {
      transform: rotateZ(0deg) translateY(0px);
    }
    25% {
      transform: rotateZ(1deg) translateY(-2px);
    }
    50% {
      transform: rotateZ(0deg) translateY(1px);
    }
    75% {
      transform: rotateZ(-1deg) translateY(-1px);
    }
  }
  
