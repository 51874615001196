:root {
    --gap-horizontal: 10px;
    --gap-vertical: 5px;
    --time-anim: 4s;
    --delay-anim: 2s;
    --blend-mode-1: none;
    --blend-mode-2: none;
    --blend-mode-3: none;
    --blend-mode-4: none;
    --blend-mode-5: overlay;
    --blend-color-1: transparent;
    --blend-color-2: transparent;
    --blend-color-3: transparent;
    --blend-color-4: transparent;
    --blend-color-5: #af4949;
  }
  
  .glitch {
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* aspect-ratio: 1/1; */
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .glitch__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* ça reste ici, mais dépend de .glitch */
    object-fit: cover;
    pointer-events: none;
    z-index: 2;
  }
  
  .glitch__item:nth-child(n+2) {
    opacity: 0;
    animation-duration: var(--time-anim);
    animation-delay: var(--delay-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .glitch__item:nth-child(2) {
    background-color: var(--blend-color-2);
    background-blend-mode: var(--blend-mode-2);
    animation-name: glitch-anim-2;
  }
  
  .glitch__item:nth-child(3) {
    background-color: var(--blend-color-3);
    background-blend-mode: var(--blend-mode-3);
    animation-name: glitch-anim-3;
  }
  
  .glitch__item:nth-child(4) {
    background-color: var(--blend-color-4);
    background-blend-mode: var(--blend-mode-4);
    animation-name: glitch-anim-4;
  }
  
  .glitch__item:nth-child(5) {
    background-color: var(--blend-color-5);
    background-blend-mode: var(--blend-mode-5);
    animation-name: glitch-anim-flash;
  }
  
  /* ANIMATIONS */
  
  @keyframes glitch-anim-2 {
    0% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6% {
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8% {
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12% {
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14% {
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16% {
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18% {
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20% {
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  
  @keyframes glitch-anim-3 {
    0% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  
  @keyframes glitch-anim-4 {
    0% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  
  @keyframes glitch-anim-flash {
    0%, 5% {
      opacity: 0.2;
      transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
    }
    5.5%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  

  .parallax-img {
    overflow: hidden;
    position: relative;
    height: 100%;
  }


  .glitch-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
  }
  
  .glitch-wrapper img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .glitch-base {
    position: relative;
    z-index: 1;
  }
  
  .glitch__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 2;
    opacity: 0;
    animation-duration: var(--time-anim);
    animation-delay: var(--delay-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    object-fit: cover;
    pointer-events: none;
  }
  
  /* On applique les bonnes animations */
  .glitch-layer-2 {
    mix-blend-mode: var(--blend-mode-2);
    background-color: var(--blend-color-2);
    animation-name: glitch-anim-2;
  }
  .glitch-layer-3 {
    mix-blend-mode: var(--blend-mode-3);
    background-color: var(--blend-color-3);
    animation-name: glitch-anim-3;
  }
  .glitch-layer-4 {
    mix-blend-mode: var(--blend-mode-4);
    background-color: var(--blend-color-4);
    animation-name: glitch-anim-4;
  }
  .glitch-layer-5 {
    mix-blend-mode: var(--blend-mode-5);
    background-color: var(--blend-color-5);
    animation-name: glitch-anim-flash;
  }
  .carousel-caption {
    z-index: 10 !important;
    position: absolute; /* déjà par défaut dans Bootstrap */
  }
  .glitch-slide-wrapper {
    position: relative;
    overflow: hidden;
  }