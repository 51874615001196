@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  box-sizing: border-box;
}





.cards {

    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 820px;
}

.card--1 .card__img, .card--1 .card__img--hover {
    background-image: url('https://images.pexels.com/photos/45202/brownie-dessert-cake-sweet-45202.webp?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.card--2 .card__img, .card--2 .card__img--hover {
    background-image: url('https://images.pexels.com/photos/307008/pexels-photo-307008.webp?auto=compress&cs=tinysrgb&h=750&w=1260');
}

.card__like {
    width: 18px;
}

.card__clock {
    width: 15px;
  vertical-align: middle;
    fill: #AD7D52;
}
.card__time {
    font-size: 12px;
    color: #AD7D52;
    vertical-align: middle;
    margin-left: 5px;
}

.card__clock-info {
    float: right;
}

.card__img {
  visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
  
}

.card__info-hover {
    position: absolute;
    padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.6s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  position: absolute;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;
  
}
.card {
  margin-right: 2px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
    width: 33.3%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}
.card:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.03, 1.03);
}

.card__info {
z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
   padding: 16px 24px 24px 24px;
}

.card__category {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
  color: #868686;
}

.card__title {
    margin-top: 5px;
    margin-bottom: 10px;
    font-family: 'Roboto Slab', serif;
}

.card__by {
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: #727982;
}

.card__author {
    font-weight: 600;
    text-decoration: none;
    color: #AD7D52;
}

.card:hover .card__img--hover {
    height: 100%;
    opacity: 0.3;
}

.card:hover .card__info {
    background-color: transparent;
    position: relative;
}

.card:hover .card__info-hover {
    opacity: 1;
}




/* Cards de l'Annapurna */
.card--nar-phu-valley-trek .card__img,
.card--nar-phu-valley-trek .card__img--hover {
  background-image: url("/public/Nar Phu Valley Trek.webp");
}
.card--mardi-himal-trekking .card__img,
.card--mardi-himal-trekking .card__img--hover {
  background-image: url("/public/Mardi Himal Trekking.webp");
}
.card--tilicho_and_mesokanta_la_pass .card__img,
.card--tilicho_and_mesokanta_la_pass .card__img--hover {
  background-image: url("/public/TilichoMesokantaLaPass.webp");
}
.card--khopra_trek .card__img,
.card--khopra_trek .card__img--hover {
  background-image: url("/public/Khopra Trek.webp");
}
.card--panchase_trek .card__img,
.card--panchase_trek .card__img--hover {
  background-image: url("/public/Panchase Trek.webp");
}
.card--jomsom_muktinath_trek .card__img,
.card--jomsom_muktinath_trek .card__img--hover {
  background-image: url("/public/Jomsom Muktinath Trek.webp");
}
.card--ghorepani_poon_hill_trek .card__img,
.card--ghorepani_poon_hill_trek .card__img--hover {
  background-image: url("/public/Ghorepani Poon Hill Trek.webp");
}
.card--annapurna_circuit_trek .card__img,
.card--annapurna_circuit_trek .card__img--hover {
  background-image: url("/public/Annapurna Circuit Trek.webp");
}
.card--annapurna_base_camp_trek .card__img,
.card--annapurna_base_camp_trek .card__img--hover {
  background-image: url("/public/Annapurna Base Camp Trek.webp");
}


/* Cards de l'Everest */
.card--everest-three-pass-trek .card__img,
.card--everest-three-pass-trek .card__img--hover {
  background-image: url("/public/Everest Three Pass Trek.webp");
}
.card--everest-base-camp-trek .card__img,
.card--everest-base-camp-trek .card__img--hover {
  background-image: url("/public/Everest Base Camp Trek.webp");
}
.card--everest-gokyo-trek .card__img,
.card--everest-gokyo-trek .card__img--hover {
  background-image: url("/public/Everest – Gokyo Trek.webp");
}

/* Card du Dolpo */
.card--upper-dolpo-trek .card__img,
.card--upper-dolpo-trek .card__img--hover {
  background-image: url("/public/Upper Dolpo Trek.webp");
}

/* Card du Mustang */
.card--lo-mangthang .card__img,
.card--lo-mangthang .card__img--hover {
  background-image: url("/public/Lo Mangthang.webp");
}

/* Card du Dhaulagiri */
.card--dhaulagiri-circuit-trek .card__img,
.card--dhaulagiri-circuit-trek .card__img--hover {
  background-image: url("/public/dhaulagiri.webp");
}
/* Card du Langtang */
.card--langtang-gosainkunda-and-helambu-trek .card__img,
.card--langtang-gosainkunda-and-helambu-trek .card__img--hover {
  background-image: url("/public/langtang-gosaikunda-3.webp");
}

/* Card de Manaslu*/
.card--manasulu-trek .card__img,
.card--manasulu-trek .card__img--hover {
  background-image: url("/public/manaslu.webp");
}

/* Card de Kanchanjanga */
.card--kanchanjanga-circuit-trekking .card__img,
.card--kanchanjanga-circuit-trekking .card__img--hover {
  background-image: url("/public/Kanchanjanga.webp");
}
/* ...et ainsi de suite */
.cards-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    cursor: pointer;
  }
  
  .card {
    width: calc(25% - 30px); /* 4 par ligne avec gap */
    height: 500px;
  }
  
  .card__button {
    background-image: linear-gradient( 89.9deg,  rgba(208,246,255,1) 0.1%, rgba(255,237,237,1) 47.9%, rgba(255,255,231,1) 100.2% );
    font-family: "Lora", serif;
    color: white;
    text-align: center;
    padding: 10px 16px;
    border-radius: 80px;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    transition: letter-spacing 0.3s ease, transform 0.2s ease;
  }
  .containerCardTrek{
    margin-bottom: 30px;
  }
  
  .card__button:hover {
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% );
    transform: translateY(-2px);
    text-decoration: none;
    letter-spacing: 3px;
    color: white;
  }
  @media screen and (max-width: 992px) {
    .card {
      width: calc(50% - 20px); /* 2 par ligne en tablette */
    }
  }
  
  @media screen and (max-width: 576px) {
    .card {
      width: 100%; /* 1 par ligne en mobile */
    }
  }
  






  .trek-info-table {
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .trek-info-table th {
    width: 200px;
    background-color: #f1f1f1;
    font-weight: 600;
    vertical-align: middle;
  }
  
  .trek-info-table td {
    vertical-align: middle;
  }
  
  .difficulty-icons {
    display: flex;
    gap: 5px;
  }
  
  .boot-icon {
    font-size: 1.3rem;
    opacity: 0.2;
    transition: opacity 0.3s ease;
  }
  
  .boot-icon.active {
    opacity: 1;
    color: #4e5ed3;
  }
  