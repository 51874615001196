.support-logo-link {
    text-decoration: none;
    display: inline-block;
    transition: transform 0.3s ease;
    width: clamp(90px, 16vw, 140px);
  }
  
  .support-logo-link:hover {
    transform: scale(1.08);
  }
  
  .support-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
/*👉    border: 1px solid red;👈*/
  }
  

  .support-logo-img {

    max-width: 70px;     /* ne dépasse jamais 70px */
    min-width: 50px;     /* ne descend jamais sous 45px */
    height: auto;
    object-fit: contain;
    display: block;
  }
  
  
  
  .arc-text {
    width: 90px;
    height: 70px;
    margin-bottom: -10px;
  }
  
  .arc-bottom {
    margin-top: -50px;
    /* border: 1px solid red; */
  }
  
  .arc-text text {
    fill: #d8d51d;
    font-family: "Lora", serif;
    font-size: 25px;
    letter-spacing: 1px;
  }
  .arc-top {
    margin-bottom: -45px;
    margin-top: 0 !important;
/*👉    border: 1px solid red !important;👈*/
  }


@media (max-width: 992px) {
    .support-logo-img {
        width: 50px !important;
        height: 50px !important;
        object-fit: contain;
        display: block;
      }
    .arc-bottom {
        margin-top: -35px;
      }
      .arc-text text {
        fill: #d8d51d;
        font-family: "Lora", serif;
        font-size: 20px;
        letter-spacing: 1px;
      }
}
@media (max-width: 992px) {
    .arc-top {
      margin-bottom: -35px;
    }
  }