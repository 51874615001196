
  .footer-edge {
    width: 100%;
    height: 1px;
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent 10px,
      #0C1B2E 10px,
      #0C1B2E 20px
    );
    background-size: 1px 1px;
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      0% 100%
    );
  }
  
.containerfooterTag{
  position: relative;
}
/*👉.footer-triangle-border {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='20' viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,20 15,0 30,20' fill='%230C1B2E'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 30px 20px;
  z-index: 3;
}👈*/

.footer-triangle-border {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='20' viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,20 15,0 30,20' fill='%230C1B2E'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 30px 20px;
  z-index: 3;
}
.footer-triangle-border2 {
  position: absolute;
  bottom: 55px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='20' viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,20 15,0 30,20' fill='%23000000'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 30px 20px;
  z-index: 3;
}
.footer-triangle-border3 {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='30' height='20' viewBox='0 0 30 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,0 15,20 30,0' fill='%230C1B2E'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-size: 30px 20px;
  z-index: 9999999999999999999 !important;

}


@keyframes triangleScroll {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 30px;
  }
}

.division_parts_footer {
  position: relative;
  z-index: 1;
}

.division_parts_footer::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: repeating-linear-gradient(
    to bottom,
    #000000 0px,
    #000000 10px,
    transparent 10px,
    transparent 20px
  );
  pointer-events: none;
  z-index: 0;
}
