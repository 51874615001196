.review-row {
    flex-wrap: nowrap;
    overflow: hidden;


  }
  
  /* Cartes côte à côte sans wrap */
  .cards-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex: 1;
    overflow: hidden;
    padding: 20px 0px;
  }
  
  .review-card {
    width: 100%;
    max-width: 300px;
    min-width: 250px;
    margin: 0 10px;
    flex-shrink: 0;
  }
  
  /* Flèches stylisées */
  .arrow-btn {
    background-color: #024168;
    color: white;
    border: none;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
  }
  
  .arrow-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .arrow-btn:hover:not(:disabled) {
    background-color: #035891;
  }
  
  .reviewtext{
    color:#727982;

  }

  .reviewTitle{
    margin-top: 30px;
  }


  .tripadvisor-circles {
    display: flex;
    margin-top: 8px;
  }
  
  .circle {
    width: 14px;
    height: 14px;
    margin: 0px;
    padding: 0px;
    border: 1px solid red;
    border-radius: 50%;
    display: inline-block;
    border: 2px solid #30A67E; /* vert TripAdvisor */
  }
  
  .circle.filled {
    background-color: #30A67E;
  }
  
  .circle.empty {
    background-color: transparent;
  }
  