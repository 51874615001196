.content {
  width: 100%;
  max-width: 800px;
  height: 75vh;
  margin: 0 auto 20px;
  background-color: black !important;
  position: relative;


  overflow: hidden;
  padding-top: 130px;
  /* background-color: #0C1B2E !important; */



}

.film {
  width: 100%;
  height: 100%;
  background: url("../public/images/1.webp") center center / cover no-repeat;
  position: relative;
}

.effect {
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  background: rgba(255, 255, 255, 0.05);
  animation: flicker 2s infinite;
  pointer-events: none;
}

.grain {
  width: 100%;
  height: 100%;
  background: url("https://media.giphy.com/media/oEI9uBYSzLpBK/giphy.gif") repeat;
  background-size: cover;
  mix-blend-mode: overlay;
  opacity: 0.4;
}

.invert .film {
  filter: invert(1);
}

.sepia .film {
  filter: sepia(1);
}

@keyframes flicker {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.4;
  }
}


.effect-buttons {
  margin-bottom: 20px;
  text-align: center;
}

.effect-buttons button {
  margin: 0 10px;
  padding: 8px 16px;
  background: #222;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-dots {
  text-align: center;
  margin-bottom: 10px;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #555;
}

.thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 40px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: transform 0.2s;
}

.thumbnail:hover {
  transform: scale(1.05);
}

.thumbnail.selected {
  border-color: #5b3c11;
}


