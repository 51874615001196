.mountains-container {
    position: absolute; /* ou relative selon ton layout */
    top: 0px;
    width: 100%;
    height: 150px; /* ajuste selon la hauteur de tes images */
    overflow: hidden;
    z-index: 20;


  }
  
  .mountains-strip-wrapper {
    display: flex;
    animation: scroll-mountains 60s linear infinite;
  }
  
  .mountains-strip {
    display: flex;
  }
  .vk{
    position: relative;
  }
  .mountain {
    height: 150px;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    display: block;
  }
  @keyframes scroll-mountains {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
    